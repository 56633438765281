import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Tariff } from './DataTypes';
import PlanSubscription from 'src/components/Subscriptions/PlanSubscription';
import ModuleSubscription from 'src/components/Subscriptions/ModuleSubscription';
import { isToRenewal } from 'src/components/Subscriptions/subscriptionMethods';
import LicenseSelect from 'src/components/Subscriptions/LicenseSelect';
import PaymentToolbar from 'src/components/Subscriptions/PaymentToolbar';
import RebateForm from 'src/components/Subscriptions/RebateForm';
import AddModuleSubscription from 'src/components/Subscriptions/AddModuleSubscription';
import { License, LicensePeriod, Product, ProductsClient, Subscription } from 'src/api/licensing/Licensing';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Spinner from 'src/components/Feedback/Spinner';

const calculateRenewalPrice = (subscription: Subscription): number => {
  const period = subscription.period;
  const renewalPrice = subscription.product?.prices?.find(p => p.period === period);
  if (!renewalPrice) return 0;
  return renewalPrice.price || 0;
}

const calculateProductPrice = (product?: Product): number => {
  if (!product) return 0;
  const period = LicensePeriod.Monthly;
  let pricing = product?.prices?.find(p => p.period === period);
  if (pricing) {
    pricing = product?.prices?.find(_p => true);
  }
  return pricing?.price || 0;
}

const SubscriptionView = () => {
  const [products, setProducts] = useState<Product[] | undefined>();
  const [license, setLicense] = useState<License | undefined>();
  const [renewal, setRenewal] = useState<string[]>([]);
  const [activate, setActivate] = useState<string[]>([]);
  const [activeNewModules, setActiveNewModules] = useState(false);
  const [rebate, setRebate] = useState<number | undefined>(undefined);

  const apiConfiguration = useApiConfiguration();
  const productsClient = new ProductsClient(apiConfiguration);

  useEffect(() => {
    setActiveNewModules(false)
  }, [license]);

  const fetchProducts = () => {
    productsClient
      .get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then(response => setProducts(response.items))
      .catch(response => console.error(response));
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  const tariff: Tariff[] = [
    { name: 'Powiadomienia SMS', amount: 0, price: 0.123 }
  ];

  const toggleRenewal = (subscriptionId: string, value: boolean) => {
    if (value && !renewal.includes(subscriptionId)) {
      setRenewal([...renewal, subscriptionId]);
    } else if (!value && renewal.includes(subscriptionId)) {
      setRenewal([...renewal.filter(s => s !== subscriptionId)]);
    }
  }

  const toggleActivate = (productId: string, value: boolean) => {
    if (value && !activate.includes(productId)) {
      setActivate([...activate, productId]);
    } else if (!value && activate.includes(productId)) {
      setActivate([...activate.filter(s => s !== productId)]);
    }
  }

  const renewalPrice = ((activate.length > 0 || renewal.length > 0) ? _.sum(renewal.map(
    r => {
      const subscription = license?.subscriptions?.find(s => s.id == r);
      if (!subscription) return 0;
      return calculateRenewalPrice(subscription);
    }
  )) : _.sum(
    license?.subscriptions?.map(s => {
      const lastRenewal = s.renewals?.find(_r => true);
      if (!lastRenewal || isToRenewal(s)) {
        return calculateRenewalPrice(s);
      }
      return 0;
    })));

  const activatePrice = (activate.length > 0 ? _.sum(activate.map(a => calculateProductPrice(products?.find(p => p.id === a)))) : 0);

  const updateSubscription = (s: Subscription) => {
    setLicense({ ...license, subscriptions: license?.subscriptions?.map(sub => sub.id === s.id ? s : sub) } as License);
  }

  const addSubscription = (s: Subscription) => {
    setLicense({ ...license, subscriptions: [...license?.subscriptions ?? [], s] } as License);
  }

  const calculatePrice = renewalPrice + activatePrice - (rebate || 0);

  const planSubscriptions = license?.subscriptions?.filter(s => s.product?.key?.startsWith('plan')) || [];
  const moduleSubscriptions = license?.subscriptions?.filter(s => s.product?.key?.startsWith('module')) || [];

  return (
    <>
      <LicenseSelect selectedLicense={license} onSelect={setLicense} />
      {!license && <Spinner className="size-32 mx-auto my-16" />}
      <hr className="my-8" />
      <PaymentToolbar renewal={renewal} calculatePrice={calculatePrice} activate={activate} />
      <h1 className="text-4xl mb-8 text-slate-700">Twój aktualny plan</h1>
      {planSubscriptions?.map(subscription => <PlanSubscription
        subscription={({ ...subscription, product: { ...subscription.product, ...(products?.find(p => p.id === subscription.productId) || {}) } } as Subscription)}
        updateSubscription={updateSubscription}
        isSelected={renewal.includes(subscription.id!)}
        onSelect={(v) => toggleRenewal(subscription.id!, v)}
        tariff={tariff}
      />)}

      {moduleSubscriptions.length > 0 &&
        <>
          <h1 className="text-4xl my-8 text-slate-700">Moduły</h1>
          {moduleSubscriptions?.map(subscription => <ModuleSubscription
            subscription={({ ...subscription, product: { ...subscription.product, ...(products?.find(p => p.id === subscription.productId) || {}) } } as Subscription)}
            updateSubscription={updateSubscription}
            isSelected={renewal.includes(subscription.id!)}
            onSelect={(v) => toggleRenewal(subscription.id!, v)}
          />)}
        </>
      }
      {activeNewModules &&
        <>
          <h1 className="text-4xl my-8 text-slate-700">Aktywuj moduły</h1>
          {products?.filter(p => p.key?.startsWith('module') && p.isEnabled).filter(p => !license?.subscriptions?.some(s => s.productId == p.id)).map(product => <AddModuleSubscription product={product} license={license} addSubscription={addSubscription} isSelected={activate.includes(product.id!)} onSelect={((v) => toggleActivate(product.id!, v))} />)}
        </>
      }
      {calculatePrice > 0 &&
        <div className="items-end my-8">
          <div className="max-w-sm ml-auto">
            <RebateForm rebate={rebate} setRebate={setRebate} />
          </div>
        </div>}

      <PaymentToolbar activeNewModules={activeNewModules} setActiveNewModules={setActiveNewModules} renewal={renewal} activate={activate} calculatePrice={calculatePrice} />
      <div className="flex flex-col gap-y-3 my-8 text-gray-500 text-sm">
        <div>
          Faktura VAT za przedłużenie ważności planu lub modułu zostanie wysłana elektronicznie na adres e-mail podany w danych rozliczeniowych do siedmiu dni po dokonanej płatności w formie zbiorczej.
        </div>
        <div>
          Faktura VAT za opłaty taryfowe zostanie wysłana elektronicznie do siedmiu dni po zakończeniu okresu rozliczeniowego planu.
        </div>
      </div>
    </>
  );
}

export default SubscriptionView;