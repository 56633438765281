import useEntityTranslation from "src/hooks/useEntityTranslation";
import { getLastRenewal, isToRenewal } from "./subscriptionMethods";
import { useState } from "react";
import CanceledInfo from "./CanceledInfo";
import PaidInfo from "./PaidInfo";
import ModuleCancelationModal from "./ModuleCancelationModal";
import SubscriptionProgressBar from "./SubscriptionProgressBar";
import SubscriptionPaymentButton from "./SubscriptionPaymentButton";
import { useTranslation } from "react-i18next";
import { LicenseStatus, Product, ProductTranslation, Subscription } from "src/api/licensing/Licensing";


interface ModuleSubscriptionProps {
  subscription: Subscription;
  updateSubscription: (subscription: Subscription) => void;
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
}

const ModuleSubscription = (props: ModuleSubscriptionProps) => {
  const { subscription, updateSubscription, isSelected, onSelect } = props;
  const { i18n } = useTranslation();
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();
  const isRenewable = isToRenewal(subscription);
  const lastPaidRenewal = getLastRenewal(subscription, true);

  const [cancelationModal, setCancelationModal] = useState(false);
  const [isCanceled, setCanceled] = useState(false);

  return (
    <div className="bg-gray-50 p-5 rounded-3xl my-3">
      <div className="md:flex md:gap-x-10 md:divide-x md:divide-gray-200">
        <div className="p-5 flex-1">
          {subscription.status === LicenseStatus.Active && <h3 className="text-primary-700 mb-3 font-medium">Bieżąca subskrypcja</h3>}
          {subscription.status === LicenseStatus.Inactive && <h3 className="text-gray-300 mb-3">Nieaktywna subskrypcja</h3>}
          {subscription.status === LicenseStatus.Unpaid && <h3 className="text-rose-700 mb-3">Nieopłacona subskrypcja</h3>}
          {subscription.status === LicenseStatus.Canceled && <h3 className="text-gray-300 mb-3">Anulowana subskrypcja</h3>}
          <h2 className="text-gray-700 font-medium text-3xl">
            {isRenewable && false && <input id={`subscription-${subscription.id}`} type="checkbox" checked={isSelected} onChange={e => onSelect(e.target.checked)} className="mr-3" />}
            <label htmlFor={`subscription-${subscription.id}`}>{productTranslation.getCurrentTranslation(subscription.product)?.name}</label>
          </h2>
          {subscription.status === LicenseStatus.Unpaid && <h3 className="text-rose-700 mt-3">Usługa nieaktywna</h3>}
          {
            subscription.status !== LicenseStatus.Unpaid &&
            <>
              {
                isCanceled ?
                  <h4 className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer" onClick={() => setCanceled(false)}>Kontynuuj subskrypcję</h4> :
                  <h4 className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer" onClick={() => setCancelationModal(true)}>Anuluj subskrypcję</h4>
              }
            </>
          }
        </div>
        <div className="p-5 flex-1">
          <h3 className="text-gray-500 mb-3">Okres ważności</h3>
          <h2 className="text-gray-700 font-medium text-3xl">{lastPaidRenewal?.expiryDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}</h2>
          <h4 className="text-gray-400 text-sm mt-5">Do tego dnia Twoje usługi pozostaną aktywne</h4>
        </div>
        {
          isCanceled ? <CanceledInfo /> : (isRenewable ?
            <SubscriptionPaymentButton subscription={subscription} updateSubscription={updateSubscription} /> :
            <PaidInfo />)
        }
      </div>
      <SubscriptionProgressBar subscription={subscription} />
      <ModuleCancelationModal subscription={subscription} visible={cancelationModal} setVisibe={setCancelationModal} setCanceled={setCanceled} />
    </div >
  )
}

export default ModuleSubscription;