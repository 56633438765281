import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router'
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';
import usePageTracking from 'src/hooks/usePageTracking';

const FrontLayout = () => {
  usePageTracking();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default FrontLayout;