import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'

export function CallToAction() {
  return (
    <section
      id="cta"
      className="relative overflow-hidden bg-slate-950 w-dvh xl:w-auto py-48 xl:py-64 2xl:py-80"
    >
      <img
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/photos/cta.webp"
        alt="Background"
      />
      <div className="absolute inset-0 bg-slate-950 opacity-80"></div>
      <Container className="relative flex items-center h-full">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-4xl sm:text-5xl tracking-tight text-white">
            Zacznij już dzisiaj
          </h2>
          <p className="mt-8 text-xl tracking-tight text-white">
            Zwiększ efektywność swojego ośrodka jeździeckiego<br />i zacznij zarządzać nim w prosty i intuicyjny sposób.
          </p>
          <Button to="/apply" color="secondary" className="mt-16 px-5 py-3 text-xl">
            Wypróbuj za darmo przez miesiąc
          </Button>
        </div>
      </Container>
    </section>
  )
}
