import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ClientPagesClient, Content, ContentTranslation } from "src/api/cms/Cms";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

export interface MetaHeadProps {
  title?: string;
  description?: string;
  keywords?: string;
  imageUrl?: string
}

export const MetaHead = (props: MetaHeadProps) => {
  const { title, description, keywords, imageUrl } = props;
  const owner = "Redini";
  const siteTitle = title ? `${title} - ${owner}` : `${title} - System do zarządzania ośrodkami jeździeckimi`;
  const url = window.location.href;
  const domain = window.location.hostname;
  const defaultDescription = "Redini - Zaawansowany system rezerwacyjny dla Twojej stajni. Puść wodze fantazji i zaprojektuj wymarzoną stronę dla Twojej stajni.";

  const logo = imageUrl || `https://${domain}/logo-color-transparent.webp`;

  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:site_name" content={owner} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:alt" content={title || owner} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:updated_time" content={new Date().toISOString()} />

      <meta property="twitter:title" content={siteTitle} />
      <meta property="twitter:description" content={description || defaultDescription} />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={logo} />
      <meta name="twitter:image:alt" content={title || owner} />

      <link rel="canonical" href={url} />
    </Helmet>
  );
}

export interface MetaPageHeadProps {
  id?: string;
}

export interface MetaContentHeadProps {
  content?: Content;
}

export const MetaPageHead = (props: MetaPageHeadProps) => {
  const { id } = props;

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientPagesClient(apiConfiguration);
  const [page, setPage] = useState<Content | undefined>(undefined);

  useEffect(() => {
    if (!id) return;
    apiClient.findById(id)
      .then(response => setPage(response))
      .catch(console.error);
  }, [id]);

  return (
    <MetaContentHead content={page} />
  );
}

export const MetaContentHead = (props: MetaContentHeadProps) => {
  const { content } = props;

  const contentTranslation = useEntityTranslation<Content, ContentTranslation>();

  return (
    <MetaHead
      title={contentTranslation.getCurrentTranslation(content)?.meta?.title || contentTranslation.getCurrentTranslation(content)?.title}
      description={contentTranslation.getCurrentTranslation(content)?.meta?.description || contentTranslation.getCurrentTranslation(content)?.content?.replace(/<[^>]+>/g, '').substring(0, 126)}
      keywords={contentTranslation.getCurrentTranslation(content)?.meta?.keywords}
    />
  );
}