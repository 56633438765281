import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import configuration from "src/config/config";
import * as CookieConsent from "vanilla-cookieconsent";
import { setup } from "meta-pixel";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (configuration.integrations?.googleAnalyticsApiKey && CookieConsent.acceptedCategory('analytics')) {
      setTimeout(() => {
        ReactGA.send({ hitType: "page_view", page: location.pathname + location.search, title: document.title });

      }, 250);
    }
    if (configuration.integrations?.metaPixelId && CookieConsent.acceptedCategory('analytics')) {
      setTimeout(() => {
        if (!configuration.integrations?.metaPixelId) return;
        const { $fbq } = setup()
          .init(configuration.integrations?.metaPixelId)
          .pageView()
        $fbq('track', 'PageView');
      }, 250);
    }
  }, [document.title, location]);
};


export default usePageTracking;