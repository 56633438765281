import Alert from "src/components/Feedback/Alert";
import useUser from "src/hooks/useUser";

const PaymentDataView = () => {

  const user = useUser();

  return (
    <>
      <div className="p-5">
        <Alert.Information title="W tytule przelewu prosimy o wpisanie kod klienta." />
        <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6 pb-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">Nazwa odbiorcy</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                H0PE.CLOUD DAWID MARTENCZUK
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">Adres odbiorcy</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                ul. Żurawia 64, 11-036 Naglady
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">Numer rachunku odbiorcy</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                76 1140 2004 0000 3502 7864 5897
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">Tytułem</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.crmId}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">Bank</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                mBank Oddział Bankowości Detalicznej
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">BIC/SWIFT</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                BREXPLPWMBK
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

export default PaymentDataView;