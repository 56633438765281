import { Container } from 'src/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookMessenger, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import PageLink from './Cms/PageLink'

export function ReferralProgram() {
  return (
    <section
      id="referralProgram"
      aria-label="What our customers are saying"
      className="sm:-mt-16 bg-slate-50 pt-32 pb-8 lg:pb-16"
    >
      <Container className="lg:my-16">
        <div className="lg:flex">
          <div className="lg:flex-grow lg:max-w-xl xl:max-w-lg">
            <div className="mx-auto max-w-mx-auto max-w-7xl">
              <h2 className="leading-7 text-4xl text-secondary-500">Program poleceń</h2>
              <p className="mt-6 text-lg leading-8 text-slate-500">Dołącz do naszego programu poleceń i uzyskaj kody na subskrypcje
                za darmo. Poleć naszą aplikację jeździecką i zyskaj miesiąc za darmo! Im więcej polecasz, tym więcej zyskujesz. Brak ograniczeń ilości poleceń!
                Za każdą wykupioną subskrypcję z Twojego kodu polecającego otrzymujesz kod na darmowy miesiąc, tego samego lub tańszego, planu.*</p>
              <div className="my-8">
                <PageLink id="135bb7ab-e24b-4cb3-92ab-0f7b1d057717" className="inline-flex rounded-md bg-primary-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700" />
              </div>
              <p className="mt-6 text-sm leading-8 text-slate-500">* Z programu wyłączone są oferty indywidualne.</p>
            </div>
          </div>
          <div className="lg:flex-grow">
            <div className="relative">
              <img src="/images/logos/logo-color-transparent-lg.webp" className="mx-auto w-96 h-96 lg:mb-8 left-0" />
              <FontAwesomeIcon icon={faWhatsapp} className="text-7xl absolute top-1/4 right-0 md:right-auto md:left-3/4 transform md:-translate-x-1/2 lg:-translate-x-0 -translate-y-1/2 text-emerald-500" />
              <FontAwesomeIcon icon={faFacebookMessenger} className="text-6xl absolute top-1/4 md:left-1/4 transform md:-translate-x-1/2 lg:-translate-x-full -translate-y-1/2 text-sky-500" />
              <FontAwesomeIcon icon={faFacebook} className="text-6xl absolute top-3/4 right-0 md:right-auto md:left-3/4 transform md:-translate-x-1/2 lg:-translate-x-0 -translate-y-1/2 text-primary-600" />
              <FontAwesomeIcon icon={faInstagram} className="text-6xl absolute top-3/4 md:left-1/4 transform md:-translate-x-1/2 lg:-translate-x-full -translate-y-1/2 text-secondary-500" />
            </div>
          </div>
        </div><nav aria-label="Progress">
          <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            <li className="relative md:flex md:flex-1">
              <div className="group flex w-full items-center md:items-start lg:items-center">
                <span className="flex md:flex-col lg:flex-row items-center px-6 py-4 text-sm font-medium gap-y-3">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-700">
                    <span className="text-primary-700">1</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-700">Jesteś naszym klientem i masz aktywną subskrypcję Redini.</span>
                </span>
              </div>
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                  <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                </svg>
              </div>
            </li>
            <li className="relative md:flex md:flex-1">
              <div className="flex md:flex-col lg:flex-row items-center px-6 py-4 text-sm font-medium gap-y-3" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-700">
                  <span className="text-primary-700">2</span>
                </span>
                <span className="ml-4 text-sm font-medium text-slate-700">Pobierasz swój indywidualny kod polecający i przekazujesz go znajomym.</span>
              </div>
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                  <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                </svg>
              </div>
            </li>
            <li className="relative md:flex md:flex-1">
              <div className="flex md:flex-col lg:flex-row items-center px-6 py-4 text-sm font-medium gap-y-3" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-700">
                  <span className="text-primary-700">3</span>
                </span>
                <span className="ml-4 text-sm font-medium text-slate-700">Twój znajomy rejestruje stajnię z użyciem Twojego kodu polecającego.</span>
              </div>
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                  <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                </svg>
              </div>
            </li>
            <li className="relative md:flex md:flex-1">
              <div className="group flex items-center">
                <span className="flex md:flex-col lg:flex-row items-center px-6 py-4 text-sm font-medium gap-y-3">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-secondary-500 group-hover:bg-secondary-700">
                    <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-700">Po spełnieniu warunków regulaminu otrzymujesz kod rabatowy na darmowy miesiac subskrypcji.</span>
                </span>
              </div>
            </li>
          </ol>
        </nav>
      </Container>
    </section>
  )
}
