import useEntityTranslation from "src/hooks/useEntityTranslation";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { getDateOfRenewal } from "./subscriptionMethods";
import { useState } from "react";
import { License, LicensePeriod, Product, ProductTranslation, Subscription, SubscriptionBuyRequest, SubscriptionsClient } from "src/api/licensing/Licensing";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useLocalizedNavigate from "src/hooks/useNavigate";

interface AddModuleSubscriptionProps {
  product: Product;
  license?: License;
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
  addSubscription: (s: Subscription) => void;
}

interface AddModulePaymentButtonProps {
  product: Product;
  license?: License;
  addSubscription: (s: Subscription) => void;
}

const AddModuleSubscription = (props: AddModuleSubscriptionProps) => {
  const { product, license, isSelected, onSelect, addSubscription } = props;
  const { i18n } = useTranslation();

  const moduleTranslation = useEntityTranslation<Product, ProductTranslation>();
  const renewalPrice = product?.prices?.find(p => p.period === LicensePeriod.Monthly);

  return (
    <div className="bg-gray-50 p-5 rounded-3xl my-3">
      <div className="md:flex md:gap-x-10 md:divide-x md:divide-gray-200">
        <div className="p-5 flex-1">
          <h2 className="text-gray-700 font-medium text-3xl">
            {false && <input id={`product-${product.id}`} type="checkbox" checked={isSelected} onChange={e => onSelect(e.target.checked)} className="mr-3" />}
            <label htmlFor={`product-${product.id}`}>{moduleTranslation.getCurrentTranslation(product)?.name}</label>
          </h2>
          <h3 className="text-gray-500 mt-3">{moduleTranslation.getCurrentTranslation(product)?.description}</h3>
        </div>
        <div className="p-5 flex-1">
          <h3 className="text-gray-500 mb-3">Cena modułu</h3>
          <h2 className="text-gray-700 font-medium text-3xl">{renewalPrice?.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</h2>
        </div>
        <AddModulePaymentButton product={product} license={license} addSubscription={addSubscription} />
      </div>
    </div>
  )
}

const AddModulePaymentButton = (props: AddModulePaymentButtonProps) => {
  const { product, license, addSubscription } = props;
  const { i18n } = useTranslation();
  const navigate = useLocalizedNavigate();
  const [renewalPeriod, setRenewalPeriod] = useState(LicensePeriod.Monthly);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SubscriptionsClient(apiConfiguration);

  const renewalDate = getDateOfRenewal(new Date(), renewalPeriod);
  const renewalPrice = product?.prices?.find(p => p.period === renewalPeriod);

  const onClickPayment = () => {
    apiClient
      .buy({
        licenseId: license?.id,
        productId: product.id!,
        period: renewalPeriod,
      } as SubscriptionBuyRequest)
      .then(response => {
        if (!response) return;
        addSubscription({ ...response } as Subscription);
        navigate(`/payments/subscription/${response.id}`);
      })
      .catch(response => console.error(response));
  }

  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">Zakup moduł</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <Button color="secondary" onClick={onClickPayment}>Dokonaj zakupu</Button>
      </h2>
      <h4 className="text-gray-400 text-sm mt-5">Kwota: {renewalPrice?.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}<br />Przedłużenie do: {renewalDate.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}</h4>
      <h4
        className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer"
        onClick={() => setRenewalPeriod(renewalPeriod === LicensePeriod.Monthly ? LicensePeriod.Annually : LicensePeriod.Monthly)}
      >
        Chcę dokonać płatności {renewalPeriod === LicensePeriod.Monthly ? "rocznej" : "miesięcznej"}
      </h4>
    </div>
  )
}

export default AddModuleSubscription;