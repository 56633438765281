import { ModalProps } from "../Modal/Modal";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { TextField } from "../Fields";
import { Button } from "../Button";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import { getDateOfRenewal } from "./subscriptionMethods";
import { useTranslation } from "react-i18next";
import { HttpQueryFilter, LicensePeriod, Product, ProductPrice, ProductsClient, ProductTranslation, Subscription } from "src/api/licensing/Licensing";
import useApiConfiguration from "src/hooks/useApiConfiguration";

interface PlanChangeModalProps extends ModalProps {
  subscription: Subscription;
}

const periodPriceTitle = (period: LicensePeriod) => {
  switch (period) {
    case LicensePeriod.Monthly:
      return 'Cena za miesiąc';
    case LicensePeriod.Quarterly:
      return 'Cena za kwartał';
    case LicensePeriod.SemiAnnually:
      return 'Cena za pół roku';
    case LicensePeriod.Annually:
      return 'Cena za rok';
  }
}

const PlanChangeModal = (props: PlanChangeModalProps) => {
  const { visible, setVisibe, subscription } = props;
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState<string | undefined>();
  const [plans, setPlans] = useState<Product[]>([]);
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ProductsClient(apiConfiguration);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    apiClient
      .get([{ property: 'key', value: 'plan-', type: '%' }] as HttpQueryFilter[], undefined, undefined, undefined, undefined, undefined)
      .then(response => setPlans(response.items || []))
      .catch(console.error);
  }
  const selectedSplit = selected?.split(',');
  const pricePlan: ProductPrice | undefined = (selectedSplit && selectedSplit?.length === 2) ? plans.find(p => p.id === selectedSplit[0])?.prices?.find(p => p.period === Number(selectedSplit[1])) : undefined;

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center ${visible ? '' : 'hidden'}`}>
      <div className="bg-white p-5 rounded-lg max-w-7xl max-h-dvh overflow-y-auto">
        <h2 className="text-2xl text-gray-700">Zmiana subskrypcji planu</h2>
        <div className="flex gap-x-5">
          {plans
            .filter(p => p.prices?.length && p.isEnabled)
            .filter(p => p.id !== subscription.productId)
            .map(plan => <div className={`flex flex-col gap-y-3 my-3 p-5 rounded-xl ${selectedSplit?.length === 2 && selectedSplit[0] === plan.id ? 'bg-primary-100' : 'bg-gray-50 hover:bg-gray-100 active:bg-primary-100'}`}>
              <div className="text-3xl font-medium">{productTranslation.getCurrentTranslation(plan)?.name}</div>
              <div className="text-gray-500">{productTranslation.getCurrentTranslation(plan)?.description}</div>
              <div>{productTranslation.getCurrentTranslation(plan)?.slogan}</div>
              <div className="flex flex-col gap-y-1">
                {productTranslation.getCurrentTranslation(plan)?.features?.split(';')?.map(feature => <div><FontAwesomeIcon icon={faCheckCircle} className="opacity-50 mr-3" /> {feature}</div>)}
              </div>
              {plan.prices?.map(price => <div className="flex">
                <input type="radio" name="plan" id={`plan-${plan.key}-${price.period}`} value={`${plan.id},${price.period}`} className="mr-3 mt-1" onChange={(e) => e.target.checked ? setSelected(e.target.value) : undefined} checked={selected === `${plan.id},${price.period}`} />
                <label htmlFor={`plan-${plan.key}-${price.period}`} className="text-gray-700">
                  <div className="text-gray-500">{periodPriceTitle(price.period!)}: {price.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</div>
                </label>
              </div>)}
            </div>)}
        </div>
        <TextField label="Hasło" type="password" placeholder="Hasło Twojego konta" className="my-3" />
        {pricePlan && <>
          <div className="text-gray-500 text-sm">Koszt opłaty za subskrypcję: {pricePlan.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</div>
          <div className="text-gray-500 text-sm">Subskrypcja pozostanie opłacona do dnia: {getDateOfRenewal(new Date(), pricePlan.period).toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}</div>
        </>}
        <div className="text-gray-500"></div>
        <div className="flex gap-x-5 justify-between mt-8">
          <Button color="primary" className="flex-1" onClick={() => setVisibe(false)}>Zamknij</Button>
          <Button color="secondary" className="flex-1" onClick={() => { setVisibe(false); }} disabled={!pricePlan?.price}>Zmień</Button>
        </div>
      </div>
    </div>
  )
}

export default PlanChangeModal;