import { Button } from 'src/components/Button'
import { SelectField, TextAreaField, TextField } from './Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'
import useApiConfiguration from 'src/hooks/useApiConfiguration'
import { ContactClient, ContactRequest } from 'src/api/notifications/Notifications'
import useForm from 'src/hooks/useForm'
import { useEffect, useState } from 'react'
import { Dictionary } from 'lodash'
import useEventTracking from 'src/hooks/useEventTracking'

import PlansData from 'src/data/Plans.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation'
import { PricePlan, PricePlanTranslation } from './Pricing'

interface ApplyFormData {
  name: string;
  givenName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  tier: string;
  business: string;
  vatId: string;
  address: string;
  city: string;
  postCode: string;
  billingEmail: string;
  content: string;
  subdomain: string;
  referralCode: string;
}

export function ApplyForm() {
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ContactClient(apiConfiguration);
  const form = useForm({
    tier: 'basic-monthly',
    name: '',
    givenName: '',
    surname: '',
    email: '',
    phoneNumber: '',
    business: '',
    vatId: '',
    address: '',
    city: '',
    postCode: '',
    billingEmail: '',
    content: '',
    subdomain: '',
    referralCode: ''
  } as ApplyFormData);
  const [submitted, setSubmitted] = useState(false);
  const applyFormTracking = useEventTracking('generate_lead', { currency: 'PLN', value: 200.00, source: "apply form" });
  const conversionTracking = useEventTracking('conversion', {
    'send_to': 'AW-16525429803/77QtCMaQktwZEKuY-Mc9',
    'value': 200.0,
    'currency': 'PLN'
  });
  const pricing = PlansData as PricePlan[];
  const pricingTranslation = useEntityTranslation<PricePlan, PricePlanTranslation>();

  const normalizeSubdomain = (text: string) =>
    // eslint-disable-next-line no-control-regex
    (text || "").replace(/[^\x00-\x7F]/g, "").toLowerCase().replace(' ', '-');

  useEffect(() => {
    form.setData({ ...form.data, subdomain: normalizeSubdomain(form.data.subdomain) })
  }, [form.data.subdomain]);

  const onSubmit = () => {
    applyFormTracking();
    conversionTracking();
    const data = {
      subject: 'Formularz zgłoszeniowy klienta',
      content: `
        <b>Adres witryny</b>: ${form.data.subdomain}.redini.app<br/>
        <b>Pakiet</b>: ${form.data.tier}<br/>
        <b>Kod programu poleceń</b>: ${form.data.referralCode}<br/><br/>
        <b>Nazwa ośrodka</b>: ${form.data.business}<br/>
        <b>NIP</b>: ${form.data.vatId}<br/>
        <b>Adres do faktury</b>:<br/>
        ${form.data.address}<br/>
        ${form.data.postCode} ${form.data.city}<br/>
        ${form.data.billingEmail}<br/>
        ---<br/>
        <b>Uwagi</b>:<br/>
        ${form.data.content}
      `,
      personalData: {
        givenName: form.data.givenName,
        surname: form.data.surname,
        phoneNumber: form.data.phoneNumber,
        email: form.data.email,
        culture: 'pl'
      }
    } as ContactRequest;
    apiClient.send(data)
      .then(() => setSubmitted(true))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const validate = () => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
        {submitted && <div className="text bg-primary-100 border-primary-300 text-primary-800 border p-3 my-6 rounded-3xl">
          <span className="font-medium">Dziękujemy za kontakt z nami!</span> Wkrótce do Ciebie odpiszemy!
        </div>}
        {form.error && <div className="text bg-red-100 border-red-300 text-red-800 border p-3 my-6 rounded-3xl">
          <span className="font-medium">Wystąpił błąd w trakcie wysyłania formularza.</span> Prosimy spróbować ponownie później!
        </div>}
        {!submitted && <>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
            <div>
              <div className="py-3">
                <TextField
                  label="Adres e-mail"
                  {...form.input(
                    'email',
                    'email', {
                    placeholder: 'Adres e-mail',
                    autoComplete: 'email',
                    required: true
                  })}
                />
              </div>
              <div className="py-3">
                <TextField
                  label="Numer telefonu"
                  {...form.input(
                    'phoneNumber',
                    'text', {
                    placeholder: 'Numer telefonu',
                    autoComplete: 'tel',
                    required: true
                  })}
                />
              </div>
              <div className="py-3 flex gap-x-3">
                <TextField
                  label="Imię"
                  {...form.input(
                    'givenName',
                    'text', {
                    placeholder: 'Imię osoby kontaktowej',
                    autoComplete: 'given-name',
                    required: true
                  })}
                />
                <TextField
                  label="Nazwisko"
                  {...form.input(
                    'surname',
                    'text', {
                    placeholder: 'Nazwisko osoby kontaktowej',
                    autoComplete: 'family-name',
                    required: true
                  })}
                />
              </div>
              <div className="py-3">
                <TextField
                  label="Adres witryny"
                  suffix=".redini.app"
                  {...form.input(
                    'subdomain',
                    'text', {
                    placeholder: 'Adres witryny',
                    required: true
                  })}
                />
                {form.data.subdomain ? <span className="text-xs text-gray-500">Dostępne równiez pod {form.data.subdomain}.redini.pl</span> : <span className="text-xs">&nbsp;</span>}
              </div>
              <div className="py-3">
                <SelectField
                  label="Pakiet oprogramowania"
                  {...form.select(
                    'tier', {
                    placeholder: 'Pakiet oprogramowania',
                    required: true
                  })}
                >
                  {pricing.filter(p => !p.disabled && p.price).map(p => <option value={`${p.key}-monthly`}>{pricingTranslation.getCurrentTranslation(p)?.name} - {p.price} PLN miesięcznie</option>)}
                  {pricing.filter(p => !p.disabled && p.priceAnnually).map(p => <option value={`${p.key}-yearly`}>{pricingTranslation.getCurrentTranslation(p)?.name} - {p.priceAnnually} PLN rocznie</option>)}
                  {pricing.filter(p => !p.price && !p.priceAnnually).map(p => <option value={p.key}>Wycena indywidualna - prosimy o wpisanie oczekiwań w informacjach dodatkowych</option>)}
                </SelectField>
              </div>
            </div>
            <div>
              <div className="py-3">
                <TextField
                  label="Nazwa przedsiębiorstwa"
                  {...form.input(
                    'business',
                    'text', {
                    placeholder: 'Nazwa przedsiębiorstwa',
                    autoComplete: 'company'
                  })}
                />
              </div>
              <div className="py-3">
                <TextField
                  label="NIP"
                  {...form.input(
                    'vatId',
                    'text', {
                    placeholder: 'NIP',
                    autoComplete: 'company'
                  })}
                />
              </div>
              <div className="py-3">
                <TextField
                  label="Adres"
                  {...form.input(
                    'address',
                    'text', {
                    placeholder: "Ulica, numer",
                    autoComplete: 'billing address-level1',
                    required: true
                  })}
                />
              </div>
              <div className="py-3 flex gap-x-3">
                <TextField
                  label="Kod pocztowy"
                  className="w-1/3"
                  {...form.input(
                    'postCode',
                    'text', {
                    placeholder: "Kod pocztowy",
                    autoComplete: 'billing code',
                    required: true
                  })}
                />
                <TextField
                  label="Miejscowość"
                  className="flex-1"
                  {...form.input(
                    'city',
                    'text', {
                    placeholder: "Miejscowość",
                    autoComplete: 'billing city',
                    required: true
                  })}
                />
              </div>
              <div><span className="text-xs text-gray-500">&nbsp;</span></div>
              <div className="py-3">
                <TextField
                  label="Adres e-mail do rozliczeń"
                  {...form.input(
                    'billingEmail',
                    'text', {
                    placeholder: "Adres e-mail do rozliczeń",
                    autoComplete: 'billing email'
                  })}
                />
              </div>
            </div>
          </div>
          <div className="my-6">
            <TextAreaField
              label="Informacje dodatkowe"
              rows={10}
              {...form.textArea(
                'content',
                'text', {
                placeholder: "Informacje dodatkowe",
                autoComplete: 'billing email'
              })}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="py-3">
              <TextField
                label="Kod programu poleceń"
                {...form.input(
                  'referralCode',
                  'text', {
                  placeholder: "Kod programu poleceń"
                })}
              />
            </div>
          </div>
          {form.data.tier.startsWith("individual") && <p className="py-6 text-sm text-gray-600">Na zapytania dotyczące oferty indywidualnej odpowiadamy w ciągu 7 dni.</p>}
          {!form.data.tier.startsWith("individual") && <p className="py-6 text-sm text-gray-600">Po przesłaniu formularza mamy 1 dzień roboczy na weryfikację zgłoszenia. Oczekuj na wiadomość zwrotną z potwierdzeniem utworzenia konta i danymi do pierwszego logowania.</p>}
          <p className="pb-6 text-sm text-gray-600">
            Formularz skierowany jest do właścicieli ośrodków jeździeckich oraz osób posiadających prawa do reprezentacji ośrodka.
          </p>
          <p className="pb-6 text-sm text-gray-600">
            Każda stajnia ma jednokrotną mozliwość skorzystania z darmowego, miesięcznego okresu próbnego.
          </p>
          <div className="text-end my-6">
            <Button
              variant="outline"
              className="w-full md:w-auto"
            >
              <FontAwesomeIcon icon={faPaperPlane} className="text-third-600" />
              <span className="ml-3">Wyślij formularz</span>
            </Button>
          </div>
        </>}
      </form>
    </>
  )
}
