import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PaymentRequestsClient, PaymentRequest } from "src/api/financial/Accountancy";
import { HttpQueryFilter, PaymentMethod, PaymentMethodsClient, PaymentMethodTranslation, PaymentsClient, PaymentSubMethod } from "src/api/financial/Payments";
import { Product, ProductsClient, ProductTranslation, Subscription, SubscriptionsClient } from "src/api/licensing/Licensing";
import { Button } from "src/components/Button";
import ContentParse from "src/components/Cms/ContentParse";
import Spinner from "src/components/Feedback/Spinner";
import GridSelect, { GridSelectOption } from "src/components/Form/GridSelect";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

const SubscriptionPaymentView = () => {
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const [product, setProduct] = useState<Product | undefined>();
  const { id } = useParams();
  const apiConfiguration = useApiConfiguration();
  const subscriptionsClient = new SubscriptionsClient(apiConfiguration);
  const productsClient = new ProductsClient(apiConfiguration);
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  useEffect(() => {
    if (subscription?.id === id) return;
    fetchSubscription();
  }, [subscription, id]);

  useEffect(() => {
    fetchProduct();
  }, [subscription?.productId]);

  const fetchSubscription = () => {
    if (!id) return;
    subscriptionsClient
      .find(id)
      .then(response => setSubscription(response))
      .catch(console.error);
  }

  const fetchProduct = () => {
    if (!subscription?.productId) return;
    productsClient
      .find(subscription?.productId)
      .then(response => setProduct(response))
      .catch(console.error);
  }

  return (
    <PaymentView
      title={product ? `Subskrypcja ${productTranslation.getCurrentTranslation(product)?.name}` : undefined}
      externalTags={subscription ? `SubscriptionId=${subscription.id}` : undefined}
    />
  )

}

interface PaymentViewProps {
  title?: string;
  externalId?: string;
  externalTags?: string;
  paymentMethodId?: string;
}

const PaymentView = (props: PaymentViewProps) => {
  const { title, externalId, externalTags, paymentMethodId } = props;
  const { i18n } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>();
  const [subMethods, setSubMethods] = useState<PaymentSubMethod[]>([]);
  const [subMethod, setSubMethod] = useState<PaymentSubMethod | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const paymentsClient = new PaymentsClient(apiConfiguration);
  const paymentRequestsClient = new PaymentRequestsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();

  useEffect(() => {
    if (!paymentMethod) {
      fetchPaymentMethod();
      return;
    }
    if (paymentMethod.haveSubMethods) {
      setLoading(true);
      paymentMethodsClient
        .listSubMethods(paymentMethod.id!, 100, 'PLN')
        .then(response => setSubMethods(response ?? []))
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (!externalId && !externalTags) return;
    const filters: HttpQueryFilter[] = [];
    if (externalId) {
      filters.push({ property: "ExternalId", value: externalId, type: '=' } as HttpQueryFilter);
    }
    if (externalTags) {
      filters.push({ property: "ExternalTags", value: externalTags, type: '%' } as HttpQueryFilter);
    }
    paymentRequestsClient
      .get(filters, undefined, undefined, undefined, undefined, undefined)
      .then(response => setPaymentRequest(response.items?.[0]))
      .catch(error => console.error(error))
  }, [externalId, externalTags]);

  const onClickPayment = () => {
    if (!paymentRequest || !paymentMethod) return;
    setPaymentLoading(true);
    paymentsClient
      .requestPayment(paymentRequest.id!, paymentMethod.id!, subMethod?.id)
      .then(response => {
        window.location.href = response;
        return;
      })
      .catch(error => console.error(error))
      .finally(() => setPaymentLoading(false));
  }

  const fetchPaymentMethod = () => {
    const filters: HttpQueryFilter[] = [];
    if (paymentMethodId !== undefined) {
      filters.push({ property: 'id', value: paymentMethodId, type: '=' } as HttpQueryFilter);
    } else {
      filters.push({ property: 'Provider', value: 'Przelewy24', type: '=' } as HttpQueryFilter);
    }
    paymentMethodsClient
      .get(filters, undefined, undefined, undefined, undefined, undefined)
      .then(response => setPaymentMethod(response.items?.[0]))
      .catch(console.error)
  }

  const options = subMethods.map(m => ({
    id: m.id,
    value: m.id,
    label: m.name,
    description: m.description,
    image: m.imageUrl
  }) as GridSelectOption);

  if ((externalId === undefined && externalTags === undefined) || title === undefined) {
    return <Spinner className="size-16 mx-auto my-16" />;
  }

  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-4xl text-gray-500 mb-4">Płatność</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{entityTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{title}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">Kwota: {paymentRequest?.value?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: paymentRequest?.currencyCode ?? 'PLN' })}</h2>
          </div>
          <div className="lg:w-7/12">
            {loading && <Spinner className="mx-auto h-8" />}
            {paymentMethod?.haveSubMethods &&
              <div className="h-96 overflow-y-scroll">
                <GridSelect value={paymentMethod?.id} options={options} onChange={(e) => setSubMethod(subMethods.find(i => i.id == e.target.value))} small />
              </div>
            }
            <div className="text-end">
              <Button color="primary" className="px-5 py-3 mt-8" onClick={onClickPayment} disabled={loading || (paymentMethod?.haveSubMethods && subMethod === undefined)}>
                Dokonaj płatności
                {paymentLoading && <Spinner className="ml-3 h-4" />}
              </Button>
              {entityTranslation.getCurrentTranslation(paymentMethod)?.agreements && <div className="text-sm text-gray-400 mt-8"><ContentParse>{entityTranslation.getCurrentTranslation(paymentMethod)?.agreements}</ContentParse></div>}
              <div className="text-sm text-gray-400 mt-8">Zostaniesz przekierowany do zewnętrznego serwisu naszego dostawcy płatności.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPaymentView;