const PaidInfo = () => {
  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">Status płatności</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <span className="text-emerald-700">Opłacono</span>
      </h2>
      <h4 className="text-gray-400 text-sm mt-5">Przedłużenie ważności możliwe jest 14 dni przed zakończeniem ważności subskrypcji</h4>
    </div>
  )
}

export default PaidInfo;