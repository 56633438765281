import { CancelationModalProps } from "./CancelationModal";
import { TextField } from "../Fields";
import { Button } from "../Button";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { getLastRenewal } from "./subscriptionMethods";
import { useTranslation } from "react-i18next";
import { Product, ProductTranslation, Subscription } from "src/api/licensing/Licensing";

interface PlanCancelationModalProps extends CancelationModalProps {
  subscription: Subscription;
}

const PlanCancelationModal = (props: PlanCancelationModalProps) => {
  const { visible, setVisibe, setCanceled, subscription } = props;
  const { i18n } = useTranslation();
  const planTranslation = useEntityTranslation<Product, ProductTranslation>();
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center ${visible ? '' : 'hidden'}`}>
      <div className="bg-white p-5 rounded-lg">
        <h2 className="text-2xl text-gray-700">Anulowanie subskrypcji planu</h2>
        <div className="text-gray-500 mt-3">Czy na pewno chcesz anulować subskrypcję planu {planTranslation.getCurrentTranslation(subscription?.product)?.name}?</div>
        <div className="text-gray-500">Moduł pozostanie aktywny do {getLastRenewal(subscription)?.expiryDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}.</div>
        <div className="text-gray-500">Potwierdź anulowanie subksrypcji planu wpisując hasło.</div>
        <TextField label="Hasło" type="password" placeholder="Hasło Twojego konta" className="my-3" />
        <div className="flex gap-x-5 justify-between mt-8">
          <Button color="primary" className="flex-1" onClick={() => setVisibe(false)}>Zamknij</Button>
          <Button color="secondary" className="flex-1" onClick={() => { setCanceled(true); setVisibe(false); }}>Anuluj</Button>
        </div>
      </div>
    </div>
  )
}

export default PlanCancelationModal;