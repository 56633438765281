import { Helmet } from 'react-helmet';
import usePageTracking from 'src/hooks/usePageTracking';

interface SlimLayoutComponentProps {
  children: React.ReactNode;
}

const SlimLayoutComponent = (props: SlimLayoutComponentProps) => {
  const { children } = props;
  usePageTracking();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="relative flex min-h-full shrink-0 justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </main>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/photos/error.webp"
            alt="Background"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </div>
    </>
  )
}

export default SlimLayoutComponent;