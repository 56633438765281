import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import AboutUs from 'src/components/AboutUs'
import { CallToAction } from 'src/components/CallToAction'
import { ContactForm } from 'src/components/ContactForm'
import { Faqs } from 'src/components/Faqs'
import { Hero } from 'src/components/Hero'
import { MetaHead } from 'src/components/MetaHead'
import { MobileBanner } from 'src/components/MobileBanner'
import { Pricing } from 'src/components/Pricing'
import { PrimaryFeatures } from 'src/components/PrimaryFeatures'
import { ReferralProgram } from 'src/components/ReferralProgram'
import { SecondaryFeaturesNew } from 'src/components/SecondaryFeaturesNew'

export default function Index() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [section]);

  const getMetaTags = () => {
    switch (section) {
      case 'features':
        return {
          title: 'To co niezbędne dla Twojego ośrodka jeździeckiego',
          description: 'Nasze oprogramowanie pozwoli Ci na zarządzanie ośrodkiem jeździeckim w prosty i intuicyjny sposób stań się rozpoznawalny i zaistniej dzięki nam w sieci.',
        };
      case 'about':
        return {
          title: 'O nas',
          description: 'Niezawodność systemu na każdym etapie. Oferta szyta na miarę dla Waszego ośrodka. Rozpoznawalność i pozyskiwanie klientów.',
        };
      case 'pricing':
        return {
          title: 'Ceny dopasowane do Twoich potrzeb',
          description: 'Nasz cennik jest dostosowany do potrzeb ośrodków jeździeckich różnych rozmiarów. Zaoszczędź nawet dwa miesiące za darmo opłacając subskrypcje rocznie.',
        };
      case 'referralProgram':
        return {
          title: 'Program poleceń',
          description: 'Dołącz do naszego programu poleceń i uzyskaj kody na subskrypcje za darmo. Poleć naszą aplikację jeździecką i zyskaj miesiąc za darmo!',
        };
      case 'contact':
        return {
          title: 'Skontaktuj się z nami',
          description: 'Masz pytania, potrzebujesz więcej informacji? Napisz do nas! Chętnie odpowiemy na zadane przez Ciebie pytania.',
        };
      case 'mobile':
        return {
          title: 'Aplikacja mobilna',
          description: ' Bądź zawsze na bieżąco z wydarzeniami w stajni. Nie przegap żadnego treningu dzięki powiadomieniom Push.',
        };
      case 'cta':
        return {
          title: 'Zacznij już dzisiaj',
          description: 'Zwiększ efektywność swojego ośrodka jeździeckiego i zacznij zarządzać nim w prosty i intuicyjny sposób. Wypróbuj za darmo przez miesiąc.',
        };
      case 'faq':
        return {
          title: 'Najczęściej zadawane pytania',
          description: 'Jaki jest cel tego oprogramowania? Co oferuje to oprogramowanie? Czy mogę wypróbować to oprogramowanie? Jaka jest cena tego oprogramowania?',
        };
      default:
        return {
          title: 'System do zarządzania ośrodkami jeździeckimi',
          description: 'Zaawansowany system rezerwacyjny dla Twojej stajni. Puść wodze fantazji i zaprojektuj wymarzoną stronę dla Twojej stajni.',
        };
    }
  };

  const metaTags = getMetaTags();

  return (
    <>
      <MetaHead {...metaTags} />
      {section && metaTags && <Helmet>
        <script id="breadcrumbs" type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Strona główna",
                "item": "https://redini.app/pl/home"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": metaTags.title,
                "item": `https://redini.app/pl/home/${section}`
              }
            ]
          })}
        </script>
      </Helmet>}
      {section === 'pricing' && metaTags && <Helmet>
        <script id="pricing" type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Redini - System do zarządzania ośrodkami jeździeckimi",
            "description": "Zaawansowany system rezerwacyjny dla Twojej stajni. Puść wodze fantazji i zaprojektuj wymarzoną stronę dla Twojej stajni.",
            "operatingSystem": "Web, Android, iOS",
            "softwareVersion": "1.0",
            "url": "https://redini.app",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://redini.app/pl/home/pricing",
                "priceCurrency": "PLN",
                "price": "200.00",
                "name": "Basic",
                "description": "Pozwól zaistnieć swojemu ośrodkowi jeździeckiemu w internecie.",
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "Poland"
                },
                "availability": "https://schema.org/InStock"
              },
              {
                "@type": "Offer",
                "url": "https://redini.app/pl/home/pricing",
                "priceCurrency": "PLN",
                "price": "300",
                "name": "Premium",
                "description": "Wejdź na wyższy poziom identyfikacji swojego ośrodka w internecie!",
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "Poland"
                },
                "availability": "https://schema.org/InStock"
              }
            ],
            "brand": {
              "@type": "Brand",
              "name": "Redini"
            },
            "applicationCategory": "BusinessApplication"
          })}
        </script>
      </Helmet>}
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeaturesNew />
      <CallToAction />
      <AboutUs />
      <MobileBanner />
      <ReferralProgram />
      <Pricing />
      <Faqs />
      <ContactForm />
    </>
  )
}
