import { Button } from "src/components/Button";

const ReturnPaymentView = () => {


  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <div className="lg:w-1/2">
          <h1 className="text-4xl text-primary-700 mt-8">Dziękujemy za dokonaną płatność</h1>
          <h2 className="text-2xl text-secondary-500 mt-8">Oczekujemy na potwierdzenie</h2>
          <p className="text-lg text-slate-700 mt-8">
            Wkrótce otrzymamy potwierdzenie płatności, a nasz system przetworzy zamówienie automatycznie.<br />
            <br />
            W razie pytań prosimy o kontakt z naszym działem obsługi klienta.
          </p>
          <Button color="primary" to="/user" className="px-5 py-3 mt-8">
            Wróć do strony głównej
          </Button>
        </div>
      </div >
    </>
  );
}

export default ReturnPaymentView;