import { Tariff } from "src/views/User/DataTypes";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { getLastRenewal, isToRenewal } from "./subscriptionMethods";
import { useState } from "react";
import CanceledInfo from "./CanceledInfo";
import PaidInfo from "./PaidInfo";
import PlanChangeModal from "./PlanChangeModal";
import PlanCancelationModal from "./PlanCancelationModal";
import _ from "lodash";
import SubscriptionProgressBar from "./SubscriptionProgressBar";
import SubscriptionPaymentButton from "./SubscriptionPaymentButton";
import { useTranslation } from "react-i18next";
import { LicensePeriod, LicenseStatus, Product, ProductTranslation, Subscription } from "src/api/licensing/Licensing";


interface PlanSubscriptionProps {
  subscription: Subscription;
  updateSubscription: (subscription: Subscription) => void;
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
  tariff?: Tariff[];
}

const PlanSubscription = (props: PlanSubscriptionProps) => {
  const { subscription, updateSubscription, tariff, isSelected, onSelect } = props;
  const { i18n } = useTranslation();

  const productTranslation = useEntityTranslation<Product, ProductTranslation>();
  const isRenewable = isToRenewal(subscription);
  const lastPaidRenewal = getLastRenewal(subscription, true);

  const [cancelationModal, setCancelationModal] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [isCanceled, setCanceled] = useState(false);

  const currentPeriod = subscription.period == LicensePeriod.Monthly ? {
    start: lastPaidRenewal?.renewalDate,
    end: lastPaidRenewal?.expiryDate
  } : {
    start: new Date(new Date().setDate(lastPaidRenewal?.renewalDate?.getDate() || new Date().getDate())),
    end: new Date(new Date().setMonth(new Date().getMonth() + 1, lastPaidRenewal?.renewalDate?.getDate() || new Date().getDate()))
  }

  return (
    <div className="bg-gray-50 p-5 rounded-3xl my-3">
      <div className="md:flex md:gap-x-10 md:divide-x md:divide-gray-200">
        <div className="p-5 flex-1">
          {subscription.status === LicenseStatus.Active && <h3 className="text-primary-700 mb-3 font-medium">Bieżąca subskrypcja</h3>}
          {subscription.status === LicenseStatus.Inactive && <h3 className="text-gray-300 mb-3">Nieaktywna subskrypcja</h3>}
          {subscription.status === LicenseStatus.Unpaid && <h3 className="text-rose-700 mb-3">Nieopłacona subskrypcja</h3>}
          {subscription.status === LicenseStatus.Canceled && <h3 className="text-gray-300 mb-3">Anulowana subskrypcja</h3>}
          <h2 className="text-gray-700 font-medium text-3xl">
            {isRenewable && false && <input id={`subscription-${subscription.id}`} type="checkbox" checked={isSelected} onChange={e => onSelect(e.target.checked)} className="mr-3" />}
            <label htmlFor={`subscription-${subscription.id}`}>{productTranslation.getCurrentTranslation(subscription.product)?.name}</label>
          </h2>
          {subscription.status === LicenseStatus.Unpaid && <h3 className="text-rose-700 mt-3">Usługa nieaktywna</h3>}
          <h4 className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer" onClick={() => setChangeModal(true)}>Zmień plan subskrypcji</h4>
          {
            subscription.status !== LicenseStatus.Unpaid &&
            <>
              {
                isCanceled ?
                  <h4 className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer" onClick={() => setCanceled(false)}>Kontynuuj subskrypcję</h4> :
                  <h4 className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer" onClick={() => setCancelationModal(true)}>Anuluj subskrypcję</h4>
              }
            </>
          }
        </div>
        <div className="p-5 flex-1">
          <h3 className="text-gray-500 mb-3">Okres ważności</h3>
          <h2 className="text-gray-700 font-medium text-3xl">{lastPaidRenewal?.expiryDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}</h2>
          <h4 className="text-gray-400 text-sm mt-5">Do tego dnia Twoje usługi pozostaną aktywne</h4>
        </div>
        {
          isCanceled ? <CanceledInfo /> : (isRenewable ?
            <SubscriptionPaymentButton subscription={subscription} updateSubscription={updateSubscription} /> :
            <PaidInfo />)
        }
      </div>
      {currentPeriod.start && currentPeriod.end &&
        <div className="border-y border-gray-200 my-5 py-5">
          <h3 className="text-gray-500 mb-3 text-lg">Opłaty taryfowe ({currentPeriod.start?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })} - {currentPeriod.end?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })})</h3>
          <div className="grid grid-cols-4 justify-between">
            {tariff?.map(t =>
              <>
                <div className="text-gray-700">{t.name}</div>
                <div className="text-gray-700 text-end">{t.amount} szt.</div>
                <div className="text-gray-700 text-end">{t.price.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN', maximumFractionDigits: 4 })}</div>
                <div className="text-gray-700 text-end">{Number(t.amount * t.price).toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</div>
              </>
            )}
            <div className="text-gray-700 font-medium mt-2">Łącznie do zapłaty</div>
            <div></div>
            <div></div>
            <div className="text-gray-700 font-medium text-end mt-2">{Number(_.sum(tariff?.map(t => t.amount * t.price) || [0])).toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</div>
          </div>
        </div>
      }
      <SubscriptionProgressBar subscription={subscription} />
      <PlanChangeModal subscription={subscription} visible={changeModal} setVisibe={setChangeModal} />
      <PlanCancelationModal subscription={subscription} visible={cancelationModal} setVisibe={setCancelationModal} setCanceled={setCanceled} />
    </div>
  )
}

export default PlanSubscription;