const CanceledInfo = () => {
  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">Status płatności</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <span className="text-rose-700">Anulowana</span>
      </h2>
    </div>
  )
}

export default CanceledInfo;