import { useTranslation } from "react-i18next";
import { Button } from "../Button";

interface PaymentToolbarProps {
  activeNewModules?: boolean;
  setActiveNewModules?: (active: boolean) => void;
  renewal: string[];
  activate: string[];
  calculatePrice: number;
}

const PaymentToolbar = (props: PaymentToolbarProps) => {
  const { activeNewModules, setActiveNewModules, renewal, activate, calculatePrice } = props;
  const { i18n } = useTranslation();
  const paySelected = renewal.length > 0 || activate.length > 0;
  return (
    <div className="flex flex-col md:flex-row justify-between my-8 gap-3">
      <div>
        {(setActiveNewModules && !activeNewModules) && <Button color="primary" className="w-full md:w-auto px-10 py-3" onClick={() => setActiveNewModules(true)}>Aktywuj dodatkowe moduły</Button>}
      </div>
      {calculatePrice > 0 &&
        <div>
          {!paySelected && <Button color="secondary" className="w-full md:w-auto px-10 py-3">Opłać wszystko ({calculatePrice.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })})</Button>}
          {paySelected && <Button color="secondary" className="w-full md:w-auto px-10 py-3">Opłać zaznaczone ({calculatePrice.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })})</Button>}
        </div>
      }
    </div>
  )
}

export default PaymentToolbar;