import { faChevronDoubleLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import ContentParse from 'src/components/Cms/ContentParse';
import { Container } from 'src/components/Container';
import { FaqData, FaqDataTranslation } from 'src/components/Faqs';
import Spinner from 'src/components/Feedback/Spinner';
import { MetaHead } from 'src/components/MetaHead';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import Data from 'src/data/Faq.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation';

export default function Faq() {
  const { id } = useParams();
  const data = Data as FaqData[];
  const faqTranslation = useEntityTranslation<FaqData, FaqDataTranslation>();
  const [realId, setRealId] = useState<string | null>(null);

  useEffect(() => { window.scrollTo(0, 0) }, []);

  useEffect(() => {
    if (!id) return;
    const splitted = id?.split('-');
    setRealId(splitted[0]);
  }, [id]);

  if (!realId) {
    return (<Spinner />);
  }

  const faq = data.find(f => f.id === realId);

  if (!faq) {
    return (<Spinner />);
  }

  return (
    <>
      <MetaHead title={faqTranslation.getCurrentTranslation(faq)?.question} description={faqTranslation.getCurrentTranslation(faq)?.answer.replace(/<[^>]+>/g, '').substring(0, 128)} />
      <Container>
        <h2 className="text-3xl text-gray-500 mb-16 pt-16">Najczęściej zadawane pytania</h2>
        <h1 className="text-4xl text-gray-800 mb-8 mt-16">{faqTranslation.getCurrentTranslation(faq)?.question}</h1>
        <div className="mb-16 text-xl">
          <ContentParse>{faqTranslation.getCurrentTranslation(faq)?.answer}</ContentParse>
        </div>
        <LocalizedLink to="/home/faq" className="pt-16 text-gray-500 text-xl">
          <FontAwesomeIcon icon={faChevronDoubleLeft} /> Powrót
        </LocalizedLink>
      </Container>
    </>
  )
}
