import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'
import { TextAreaField, TextField } from './Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'
import useApiConfiguration from 'src/hooks/useApiConfiguration'
import { ContactClient, ContactRequest } from 'src/api/notifications/Notifications'
import useForm from 'src/hooks/useForm'
import { useState } from 'react'
import { Dictionary } from 'lodash'
import useEventTracking from 'src/hooks/useEventTracking'

export function ContactForm() {
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ContactClient(apiConfiguration);
  const form = useForm({ subject: 'Formularz kontaktowy', personalData: { culture: 'pl' } } as ContactRequest);
  const [submitted, setSubmitted] = useState(false);
  const contactFormTracking = useEventTracking('generate_lead', { currency: 'PLN', value: 0.00, source: "contact form" });

  const onSubmit = () => {
    contactFormTracking();
    apiClient.send(form.data)
      .then(() => setSubmitted(true))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const validate = () => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  return (
    <section id="contact">
      <Container className="pb-16 pt-20 text-center lg:pt-32">
        <h1 className="mx-auto max-w-4xl font-display text-4xl sm:text-5xl font-medium tracking-tight text-slate-900">
          <span className="relative whitespace-nowrap text-secondary-500 tracking-tight">Skontaktuj</span> się z nami!
        </h1>
        <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-slate-700">
          Masz pytania, potrzebujesz więcej informacji? Napisz do nas!<br />
          Chętnie odpowiemy na zadane przez Ciebie pytania.
        </p>
        <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
          <div className="max-w-2xl mx-auto">
            {submitted && <div className="text-sm bg-primary-100 border-primary-300 text-primary-800 border p-3 my-6 rounded-3xl">
              <span className="font-medium">Dziękujemy za kontakt z nami!</span> Wkrótce do Ciebie odpiszemy!
            </div>}
            {form.error && <div className="text-sm bg-red-100 border-red-300 text-red-800 border p-3 my-6 rounded-3xl">
              <span className="font-medium">Wystąpił błąd w trakcie wysyłania formularza.</span> Prosimy spróbować ponownie później!
            </div>}
            {!submitted &&
              <>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6 text-start">
                  <TextField
                    label="Adres e-mail"
                    {...form.input(
                      'personalData.email',
                      'text', {
                      required: true,
                      placeholder: 'Adres e-mail',
                      autoComplete: 'email'
                    })}
                  />
                  <TextField
                    label="Numer telefonu"
                    {...form.input(
                      'personalData.phoneNumber',
                      'text', {
                      placeholder: 'Numer telefonu',
                      autoComplete: 'tel'
                    })}
                  />
                  <TextField
                    label="Imię"
                    {...form.input(
                      'personalData.givenName',
                      'text', {
                      required: true,
                      placeholder: 'Imię',
                      autoComplete: 'given-name'
                    })}
                  />
                  <TextField
                    label="Nazwa firmy"
                    {...form.input(
                      'personalData.surname',
                      'text', {
                      placeholder: 'Nazwa firmy',
                      autoComplete: 'organization'
                    })}
                  />
                </div>
                <div className="my-6 text-start">
                  <TextAreaField
                    label="Treść"
                    rows={10}
                    {...form.textArea(
                      'content',
                      'textarea', {
                      required: true,
                      placeholder: 'Treść',
                    })}
                  />
                </div>
                <div className="text-end my-6">
                  <Button
                    variant="outline"
                    className="w-full md:w-auto"
                    disabled={form.pending}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} className="text-primary-500" />
                    <span className="ml-3">Wyślij wiadomość</span>
                  </Button>
                </div>
              </>
            }
          </div>
        </form>
      </Container>
    </section>
  )
}
