import { faPresentationScreen } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'

import Data from 'src/data/Companies.json';

export interface Company {
  name: string;
  logo: string;
  link: string;
}

export const Companies = () => {
  const data = Data as Company[];
  return (
    <div className="mt-32 lg:mt-40">
      <p className="font-display text-base text-slate-900">
        Zaufało nam już wielu klientów, przekonaj się i Ty jak łatwo zaistnieć w internecie!
      </p>
      <ul
        role="list"
        className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
      >
        <li>
          <ul
            role="list"
            className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
          >
            {data.map((company) => (
              <a href={company.link} target='_blank'>
                <li key={company.name} className="flex">
                  <img src={company.logo} alt={company.name} className="h-12 grayscale hover:grayscale-0 transition-all ease-in-out delay-100 duration-300" />
                </li>
              </a>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export function Hero() {
  return (
    <div className="relative text-center -mt-20 py-32 min-h-dvh flex items-center justify-center">
      <img
        className="absolute left-0 top-0 w-full h-full object-cover grayscale opacity-30 pointer-events-none"
        src="/images/photos/hero.webp"
        alt="Background"
      />
      <Container>
        <div className="relative z-10 lg:mt-10 xl:mt-16">
          <h1 className="mx-auto max-w-4xl text-5xl font-medium font-branding tracking-tight text-slate-900 md:text-6xl 2xl:text-8xl">
            Puść{' '}
            <span className="relative whitespace-nowrap text-secondary-500 tracking-tight">wodze</span>
            {' '}fantazji<br />
            <span className="whitespace-nowrap">i zaprojektuj</span>
            <br />
            wymarzoną stronę{' '}<br />
            dla{' '}
            <span className="relative whitespace-nowrap text-primary-700 tracking-tight">Twojej stajni</span>
          </h1>
          <div className="mt-3 lg:mt-4 xl:mt-6 flex flex-col md:flex-row justify-center gap-y-6 md:gap-y-0 md:gap-x-6 mb-24">
            <Button color="secondary" className="text-lg px-5 py-3" to="/apply">Spróbuj za darmo przez miesiąc</Button>
            <Button
              to="https://wizualizacja.redini.app/"
              target="_blank"
              rel="noopener"
              color="secondary"
              variant="outline"
            >
              <FontAwesomeIcon icon={faPresentationScreen}></FontAwesomeIcon>
              <span className="ml-3">Obejrzyj przykładową stronę</span>
            </Button>
          </div>
        </div>
      </Container>
      <div className="font-branding absolute bottom-0 left-0 w-full mb-8 text-center text-4xl sm:text-5xl font-medium">
        Zaawansowany system rezerwacyjny dla <span className=" text-secondary-500 ">Twojej stajni</span>
      </div>
    </div>
  )
}
