import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ClientGalleriesClient, ClientGalleryCategoriesClient, Content, ContentTranslation, HttpQueryFilter, HttpQueryOrder, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { IndexResult } from "src/api/Interfaces";
import { setBreadcrumbs } from "src/store/application/actions";
import Pagination from "src/components/Table/Pagination";
import { QueryOrderDirection } from "src/api/Base";
import LocalizedLink from "src/components/Router/LocalizedLink";
import useLocalizedNavigate from "src/hooks/useNavigate";

import configuration, { ConfigurationApis } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import { MetaHead } from "src/components/MetaHead";

export interface GalleryElementComponentProps {
  gallery: Content;
}

export const Gallery = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [thumbnail, setThumbnail] = useState<Content | undefined>(undefined);
  const tenant = useTenant();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);

  const onLoadPhotos = (response: IndexResult<Content>) => {
    setThumbnail(response.items?.find(t => t));
    setCount(response.totalCount || 0);
  }

  useEffect(() => {
    setLoading(true);
    apiClient.getPhotos(
      gallery.id!,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    )
      .then(onLoadPhotos)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const thumbnailUrl = thumbnail ? `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${thumbnail.id}/download?XTenantId=${tenant}` : '/img/default-image.webp';

  return (
    <LocalizedLink to={`/g/g/${translation?.url}`}>
      <div className="bg-white hover:ring-primary-700 ring-1 ring-gray-200 shadow-md rounded-md px-5 py-3 mb-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 hover:shadow-xl opacity-85 hover:opacity-95 active:opacity-100">
        <h3 className="text-xl border-b border-gray-100 mb-3 pb-3">{translation?.title}</h3>
        {loading && <Spinner className="h-8 mx-auto" />}
        {!loading && <img src={thumbnailUrl} alt={thumbnail?.name || t('cms.defaultImage')} title={thumbnail?.name || t('cms.defaultImage')} className="h-auto max-w-full rounded-lg" />}
        <div className="border-t border-gray-100 mt-3 pt-3 text-xs">{t('cms.galleries.galleries.photosCount', { count: count })}</div>
      </div>
    </LocalizedLink >
  )
}

const GalleriesIndex = () => {
  const { url } = useParams<string>();
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleriesClient(apiConfiguration);
  const categoriesClient = new ClientGalleryCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [loading, setLoading] = useState(false);
  const [galleries, setGalleries] = useState<IndexResult<Content>>();
  const [category, setCategory] = useState<Content>();
  const [page, setPage] = useState(1);
  const pageSizes = [10, 15, 20, 25, 30];
  const [pageSize, setPageSize] = useState(pageSizes[0]);

  const categoryTranslation = entityTranslation.getCurrentTranslation(category);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!url) {
      if (category) {
        setCategory(undefined);
      }
      return;
    }
    setLoading(true);
    categoriesClient.findByUrl(url || "")
      .then(response => setCategory(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [url]);

  useEffect(() => {
    if (url && !category) {
      return;
    }
    setLoading(true);
    const filters: HttpQueryFilter[] = [{ property: "isVisible", value: "true", type: "=" } as HttpQueryFilter];
    if (category) {
      filters.push({ property: "parentId", value: category.id, type: "=" } as HttpQueryFilter);
    }
    apiClient.get(filters, [{ property: "created", direction: QueryOrderDirection.DESC } as HttpQueryOrder], undefined, undefined, page, 10)
      .then(response => setGalleries(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [category, page, pageSize]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.galleries.galleries.group', href: `/g` }];
    if (category) {
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/g/c/${categoryTranslation?.url ?? category?.id}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [category]);

  return (
    <>
      <MetaHead title="Galerie" description="Galerie zdjęć" />

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto">
        <div className="border-b border-gray-200 pb-5 mt-12 mb-5 flex justify-between">
          <h3 className="text-2xl font-semibold leading-6 text-gray-900">{t('cms.galleries.galleries.group')}</h3>
          <h3 className="text-2xl font leading-6 text-gray-300">{category ? categoryTranslation?.title : "All categories"}</h3>
        </div>
        <div className="my-8">
          {loading && <Spinner className="h-16 mx-auto" />}
          {!loading && galleries?.items?.map(g => <Gallery gallery={g} />)}
          {!loading && !galleries?.items?.length && <p className="text-center my-12 text-gray-400">No posts found</p>}
        </div>
        <Pagination
          page={page}
          pageSize={pageSize}
          pageSizes={pageSizes}
          total={galleries?.totalCount || 0}
          onSetPage={setPage}
          onSetPageSize={setPageSize}
          nextButton={galleries?.hasNextPage || false}
          previousButton={galleries?.hasPreviousPage || false}
          distance={3}
        />
      </div >
    </>
  )
}

export default GalleriesIndex;