import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "../Utilities/classNames";

export interface Tab {
  id: string;
  name: string;
  visible?: boolean;
}

export interface TabsComponentProps {
  tabs: Tab[];
  currentTab: string;
  setCurrentTab: (v: string) => void;
  className?: string;
  secondary?: boolean;
}

const Tabs = (props: TabsComponentProps) => {
  const { tabs, currentTab, setCurrentTab, className, secondary } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!tabs.some(t2 => t2.id === currentTab)) {
      setCurrentTab(tabs[0].id);
    }
  }, [currentTab]);

  return (
    <div className={classNames(["border-b-2 border-gray-200 pb-3 mb-8", className])}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className={`block w-full rounded-md border-gray-300 focus:${secondary ? "border-secondary-500" : "border-primary-700"} focus:${secondary ? "ring-secondary-500" : "ring-primary-700"}`}
          defaultValue={currentTab}
          value={currentTab}
          onChange={(e) => setCurrentTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>{t(tab.name)}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.filter(t => t.visible !== false).map((tab) => (
            <button
              key={tab.name}
              type="button"
              className={classNames(
                tab.id === currentTab ? `${secondary ? "bg-secondary-500" : "bg-primary-700"} text-white` : `cursor-pointer text-gray-600 hover:text-gray-800`,
                'rounded-md px-5 py-3 text-sm font-medium'
              )}
              onClick={() => setCurrentTab(tab.id)}
              aria-current={tab.id === currentTab ? 'page' : undefined}
            >
              {t(tab.name)}
            </button>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default Tabs;