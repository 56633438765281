import ReactGA from 'react-ga4';
import configuration from "src/config/config";
import * as CookieConsent from "vanilla-cookieconsent";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useEventTracking = (name: string, params?: any | undefined) => {
  return () => {
    if (configuration.integrations?.googleAnalyticsApiKey && CookieConsent.acceptedCategory('analytics')) {
      ReactGA.event(name, params);
    }
  }
};

export default useEventTracking;