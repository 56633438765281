import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { TextField } from "../Fields";
import { useState } from "react";

interface RebateFormProps {
  setRebate: (rebate?: number) => void;
  rebate?: number;
}

const RebateForm = (props: RebateFormProps) => {
  const { setRebate, rebate } = props;

  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const { i18n } = useTranslation();

  const onSubmit = () => {
    if (value === "TEST") {
      setError(false);
      setRebate(100);
    } else {
      setError(true);
    }
  }

  const onRemove = () => {
    setError(false);
    setRebate(undefined);
    setValue("");
  }

  return (
    <>
      <div className="flex justify-between gap-x-3">
        <TextField label="Kod rabatowy" placeholder="Kod rabatowy" className="flex-1" value={value} onChange={e => setValue(e.target.value)} readOnly={rebate !== undefined} />
        <div className="mt-8">
          <Button color="primary" onClick={onSubmit}>Aktywuj</Button>
        </div>
      </div>
      {error && <div className="text-rose-700 text-sm mt-3">Niepoprawny kod rabatowy</div>}
      {rebate !== undefined && <div className="text-sm mt-3">
        <div>
          <span className="font-medium">Wartość kodu rabatowego</span>: {Number(rebate * -1).toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}
        </div>
        <div className="text-primary-700 hover:text-primary-500 active:text-primary-300 cursor-pointer" onClick={onRemove}>Usuń kod rabatowy</div>
      </div>}
    </>
  )
}

export default RebateForm;