/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { Container } from 'src/components/Container'
import { faCogs, faPeopleGroup, faReel, faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const AboutUs = () => {
  return (

    <section
      id="about"
      aria-labelledby="about-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-24"
    >
      <img
        className="absolute left-0 top-0 w-full h-full object-cover"
        src='/images/photos/faq.svg'
        alt="Background"
        width={1558}
      />
      <Container className="relative mb-16">
        <div className="grid grid-cols-1 gap-y-3 xl:gap-y-8">
          <div className="flex flex-col gap-y-5">
            <h2 className="text-2xl sm:text-3xl text-primary-700 mt-8 mb-3">Przy tworzeniu naszego oprogramowania mieliśmy dwa priorytety,<br />które są fundamentem aplikacji Redini.</h2>
            <div className="flex flex-col sm:flex-row gap-x-3 bg-slate-50 p-8 rounded-xl shadow-lg drop-shadow-sm shadow-slate-200 lg:opacity-75 lg:hover:opacity-100">
              <div className="pl-5 pr-8 h-full flex flex-col justify-center border-b sm:border-b-0 sm:border-r border-slate-200">
                <FontAwesomeIcon icon={faCogs} className="mx-auto size-12 text-secondary-500 mb-5 sm:mb-0" />
              </div>
              <div className="pt-5 sm:pt-0 px-5 flex-grow text-lg text-slate-700">
                Po pierwsze <b>niezawodność systemu na każdym etapie</b>. Zdajemy sobie sprawę z tego, jak trudne potrafi być zarządzanie ośrodkiem jeździeckim. Nasza aplikacja jeździecka powstała, aby ułatwić Wam prowadzenie swojej stadniny i odciążyć Was w planowaniu.
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-x-3 bg-slate-50 p-8 rounded-xl shadow-lg drop-shadow-sm shadow-slate-200 lg:opacity-75 lg:hover:opacity-100">
              <div className="pl-5 pr-8 h-full flex flex-col justify-center border-b sm:border-b-0 sm:border-r border-slate-200">
                <FontAwesomeIcon icon={faReel} className="mx-auto size-12 text-secondary-500 mb-5 sm:mb-0" />
              </div>
              <div className="pt-5 sm:pt-0 px-5 flex-grow text-lg text-slate-700">
                Drugim priorytetem jest zaoferowanie Wam <b>oferty szytej na miarę</b> dla Waszego ośrodka. Oferowany przez nas system jest w pełni konfigurowalny przez Was. Macie możliwość stałego wprowadzania zmian za pomocą kilku kliknięć. Dzięki temu nie musicie się martwić, że jakiekolwiek większe zmiany w Waszym ośrodku wprowadzą przestój w świadczeniu usług.
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5">
            <h2 className="text-2xl sm:text-3xl text-primary-700 mt-8 mb-3">Największe atuty korzystania z Redini dla Stajni?</h2>
            <div className="flex flex-col sm:flex-row gap-x-3 bg-slate-50 p-8 rounded-xl shadow-lg drop-shadow-sm shadow-slate-200 lg:opacity-75 lg:hover:opacity-100">
              <div className="pl-5 pr-8 h-full flex flex-col justify-center border-b sm:border-b-0 sm:border-r border-slate-200">
                <FontAwesomeIcon icon={faPeopleGroup} className="mx-auto size-12 text-secondary-500 mb-5 sm:mb-0" />
              </div>
              <div className="pt-5 sm:pt-0 px-5 flex-grow text-lg text-slate-700">
                <b>Rozpoznawalność i pozyskiwanie klientów.</b> Indywidualna wizytówka WWW i system rezerwacyjny dostępny dla gości pozwola Wam na zaistnienie w sieci oraz konwersje klientów.
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-x-3 bg-slate-50 p-8 rounded-xl shadow-lg drop-shadow-sm shadow-slate-200 lg:opacity-75 lg:hover:opacity-100">
              <div className="pl-5 pr-8 h-full flex flex-col justify-center border-b sm:border-b-0 sm:border-r border-slate-200">
                <FontAwesomeIcon icon={faSearch} className="mx-auto size-12 text-secondary-500 mb-5 sm:mb-0" />
              </div>
              <div className="pt-5 sm:pt-0 px-5 flex-grow text-lg text-slate-700">
                Dodatkowo optymalizacja strony pod kątem indeksowania w wyszukiwarkach sprawia, że Wasza stajnia zacznie być <b>rozpoznawalna przez nowych użytkowników</b>.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default AboutUs;