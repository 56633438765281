import { Subscription } from "src/api/licensing/Licensing";
import { billingPeriodPercentage, getRemainDays, isPaid } from "./subscriptionMethods";

interface SubscriptionProgressBarProps {
  subscription: Subscription;
}

const SubscriptionProgressBar = (props: SubscriptionProgressBarProps) => {
  const { subscription } = props;
  const remainDays = getRemainDays(subscription);
  const value = billingPeriodPercentage(subscription);

  const paid = isPaid(subscription);

  return (
    <div>
      {paid ?
        <div className="w-full bg-gray-200 rounded-lg overflow-hidden my-2">
          <div className={`${remainDays >= 0 ? 'bg-primary-700' : 'bg-rose-700'} h-2`} style={{ width: `${value}%` }}>
          </div>
        </div> :
        <div className="w-full bg-gray-200 rounded-lg overflow-hidden my-2">
          <div className="bg-rose-700 h-2" style={{ width: `${value}%` }}>
          </div>
        </div>
      }
      {paid && remainDays >= 0 && <h4 className="text-gray-400 text-sm text-end">Pozostało {remainDays} dni</h4>}
      {!paid && remainDays !== 0 && <h4 className="text-gray-400 text-sm text-end">Płatność opóźniona o {remainDays} dni</h4>}
      {!paid && remainDays === 0 && <h4 className="text-gray-400 text-sm text-end">Oczekuje na płatność</h4>}
    </div>
  )
}

export default SubscriptionProgressBar;