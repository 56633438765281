import { useEffect, useState } from "react";
import { Buyer, BuyersClient, HttpQueryFilter, HttpQueryOrder, QueryOrderDirection } from "src/api/financial/Accountancy";
import { Button } from "src/components/Button";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import FormInput from "src/components/Form/FormInput";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useForm from "src/hooks/useForm";
import useUser from "src/hooks/useUser";

const BillingInfoView = () => {
  const user = useUser();

  const form = useForm<Buyer>();
  const [saved, setSaved] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new BuyersClient(apiConfiguration);

  const fetch = () => {
    form.setPending(true);
    apiClient
      .get(
        [{ property: 'userId', value: user?.id, type: '=' }] as HttpQueryFilter[],
        [{ property: 'created', direction: QueryOrderDirection.DESC }] as HttpQueryOrder[],
        1, undefined, undefined, undefined
      )
      .then(response => form.setData(response.items?.[0] as Buyer))
      .finally(() => form.setPending(false));
  }

  const onSubmit = () => {
    if (!form.data) return;
    form.setPending(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = form.data
    apiClient
      .create(data as Buyer)
      .then(() => fetch())
      .catch(console.error)
      .finally(() => {
        form.setPending(false)
        setSaved(true);
        window.scrollTo(0, 0);
      });
  }

  useEffect(() => {
    if (!user?.id) return;
    fetch();
  }, [user?.id]);

  return (
    <>
      <div className="p-5">
        <h1 className="text-4xl mb-8 text-slate-700">Dane do faktur</h1>
        <form onSubmit={e => form.onSubmit(e, onSubmit)}>
          {form.pending && <Spinner className="size-16 mx-auto my-16" />}
          {!form.pending && !form.data?.id && <Alert.Error title="Brak danych do faktur">Prosimy o uzupełnienie danych do faktur w celu uzyskania faktur za zakup, w innym przypadku faktura będzie wystawiona bez danych nabywcy.</Alert.Error>}
          {!form.pending && saved && <Alert.Success title="Dane zostały zapisane pomyślnie" />}
          <div className="flex flex-col gap-y-5">
            <FormInput.Overlapping {...form.input('name', 'text', { placeholder: 'Nazwa firmy', required: true, minLength: 2 })} />
            <FormInput.Overlapping {...form.input('vatId', 'text', { placeholder: 'NIP' })} pattern="^([0-9]{10})$" />
            <FormInput.Overlapping {...form.input('address', 'text', { placeholder: 'Adres', required: true, minLength: 2 })} />
            <FormInput.Overlapping {...form.input('extra', 'text', { placeholder: 'Dodatkowa linia adresowa', minLength: 2 })} />
            <div className="flex gap-x-5">
              <FormInput.Overlapping {...form.input('postCode', 'text', { placeholder: 'Kod pocztowy', required: true })} pattern="^([0-9]{2})\-([0-9]{3})$" />
              <FormInput.Overlapping {...form.input('city', 'text', { placeholder: 'Miejscowość', required: true, minLength: 2 })} />
            </div>
            <FormInput.Overlapping {...form.input('email', 'text', { placeholder: 'Adres e-mail do e-faktury', required: true, minLength: 2 })} />
          </div>
          <div className="text-end my-8">
            <Button color="primary" className="px-10 py-3">Zmień dane do faktury</Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default BillingInfoView;