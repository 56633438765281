import moment from "moment";
import { LicensePeriod, LicenseStatus, Subscription, SubscriptionRenewal } from "src/api/licensing/Licensing";

export const isToRenewal = (subscription: Subscription): boolean => {
  if (
    subscription.isCanceled ||
    (subscription.status !== LicenseStatus.Active &&
      subscription.status !== LicenseStatus.Unpaid)
  ) {
    return false;
  }
  const lastRenewal = getLastRenewal(subscription);
  if (!lastRenewal) {
    return true;
  }
  const expiryDate = lastRenewal?.expiryDate;
  if (!lastRenewal.isPaid) {
    return true;
  }
  return moment(expiryDate).subtract(14, 'days').toDate().getTime() < new Date().getTime();

}

export const isToActivate = (subscription: Subscription): boolean => {
  const expiryDate = getLastRenewal(subscription, true)?.expiryDate;
  if (!expiryDate) {
    return true;
  }
  return false
}

export const getLastRenewal = (subscription: Subscription, onlyPaid?: boolean): SubscriptionRenewal | undefined =>
  subscription
    .renewals
    ?.filter(r => onlyPaid ? r.isPaid : true)
    ?.sort((b, a) => (a.expiryDate?.getTime() || 0) - (b.expiryDate?.getTime() || 0))?.[0];

export const getSubscriptionDateOfRenewal = (subscription: Subscription): Date | undefined => {
  const lastRenewal = getLastRenewal(subscription);
  const period = subscription.period || LicensePeriod.Monthly;
  if (!lastRenewal) return getDateOfRenewal(new Date(), period);
  return getDateOfRenewal(lastRenewal.expiryDate!, period);
}

export const getDateOfRenewal = (from?: Date, period: LicensePeriod = LicensePeriod.Monthly): Date => {
  switch (period) {
    case LicensePeriod.Monthly:
      return moment(from).add(1, 'months').toDate();
    case LicensePeriod.Quarterly:
      return moment(from).add(3, 'months').toDate();
    case LicensePeriod.SemiAnnually:
      return moment(from).add(6, 'months').toDate();
    case LicensePeriod.Annually:
      return moment(from).add(1, 'year').toDate();
  }
}

export const billingPeriodPercentage = (subscription: Subscription): number => {
  const lastRenewal = getLastRenewal(subscription);
  if (!lastRenewal) return 100;
  const renewalPeriod = lastRenewal.expiryDate!.getTime() - lastRenewal.renewalDate!.getTime();
  const sinceRenewal = new Date().getTime() - lastRenewal.renewalDate!.getTime();
  return (sinceRenewal / renewalPeriod) * 100;
}

export const getRemainDays = (subscription: Subscription): number => {
  const lastRenewal = getLastRenewal(subscription, true);
  if (!lastRenewal?.expiryDate) return 0;
  const timeDifference = lastRenewal.expiryDate.getTime() - new Date().getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export const isPaid = (subscription: Subscription): boolean => {
  if (subscription.status !== LicenseStatus.Active) {
    return false;
  }
  const lastRenewal = getLastRenewal(subscription);
  if (!lastRenewal) {
    return false;
  }
  if (!lastRenewal.isPaid) {
    return true;
  }
  return true;
};