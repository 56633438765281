/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { Container } from 'src/components/Container'
import { faCreditCardAlt, faLanguage, faTicket } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Data from 'src/data/FeaturesSecondary.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation'
import { Feature, FeatureTranslation } from './PrimaryFeatures'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

const icons = [
  { id: "1", icon: faCreditCardAlt },
  { id: "2", icon: faTicket },
  { id: "3", icon: faLanguage },
  { id: "4", icon: faGoogle },
]

export function SecondaryFeaturesNew() {
  const features = Data as Feature[];
  const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();

  return (

    <section
      id="features"
      className="relative overflow-hidden bg-slate-50 py-20 lg:py-16"
    >
      <div className="absolute inset-0 bg-white-900 opacity-90"></div>
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-4xl sm:text-5xl tracking-tight text-primary-700">
            Postaw na niezawodność
          </h2>
          <p className="mt-6 text-xl tracking-tight text-slate-700">
            Istnienie firmy w internecie to nie tylko strona internetowa.
            To także systemy, które pomagają w codziennych zadaniach.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-16">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="flex group relative rounded-full px-4 py-1 lg:rounded-xl lg:p-6 transition-all duration-300 hover:bg-white/10 lg:hover:bg-white/5"
            >
              <div className="w-24 text-start">
                <FontAwesomeIcon icon={icons.find(i => i.id === feature.id)!.icon} className="h-16 text-secondary-500" />
              </div>
              <div className="flex-1">
                <h3 className="text-xl text-primary-700 font-medium">{featureTranslation.getCurrentTranslation(feature)?.title}</h3>
                <p className="mt-2 text-slate-700 group-hover:text-primary-700 text-base">
                  {featureTranslation.getCurrentTranslation(feature)?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
