import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ClientHelpCategoriesClient, ClientHelpPagesClient, Content, ContentTranslation, HttpQueryFilter, HttpQueryOrder, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { IndexResult } from "src/api/Interfaces";
import { setBreadcrumbs } from "src/store/application/actions";
import Pagination from "src/components/Table/Pagination";
import { QueryOrderDirection } from "src/api/Base";
import LocalizedLink from "src/components/Router/LocalizedLink";
import useLocalizedNavigate from "src/hooks/useNavigate";
import { MetaHead } from "src/components/MetaHead";


export interface HelpPageElementComponentProps {
  page: Content;
}

export const HelpPageRow = (props: HelpPageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  const plainText = translation?.content?.replace(/<[^>]+>/g, '') ?? "";
  return (
    <LocalizedLink to={`/h/p/${translation?.url}`}>
      <div className="ring-1 ring-gray-200 shadow-lg rounded-sm px-5 py-3 mb-5 bg-white hover:ring-primary-700">
        <div className="px-3 py-5 grow">
          <h3 className="leading-3 text-3xl mb-5">{translation?.title}</h3>
          <div className="text-gray-500 h-8">
            <p className="max-h-full text-ellipsis overflow-hidden text-justify">
              {plainText}
            </p>
          </div>
        </div>
      </div>
    </LocalizedLink >
  )
}

const HelpIndex = () => {
  const { url } = useParams<string>();
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientHelpPagesClient(apiConfiguration);
  const categoriesClient = new ClientHelpCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<IndexResult<Content>>();
  const [category, setCategory] = useState<Content>();
  const [page, setPage] = useState(1);
  const pageSizes = [10, 15, 20, 25, 30];
  const [pageSize, setPageSize] = useState(pageSizes[0]);

  const categoryTranslation = entityTranslation.getCurrentTranslation(category);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!url) {
      if (category) {
        setCategory(undefined);
      }
      return;
    }
    setLoading(true);
    categoriesClient.findByUrl(url || "")
      .then(response => setCategory(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [url]);

  useEffect(() => {
    if (url && !category) {
      return;
    }
    setLoading(true);
    const filters: HttpQueryFilter[] = [{ property: "isVisible", value: "true", type: "=" } as HttpQueryFilter];
    if (category) {
      filters.push({ property: "parentId", value: category.id, type: "=" } as HttpQueryFilter);
    }
    apiClient.get(filters, [{ property: "created", direction: QueryOrderDirection.DESC } as HttpQueryOrder], undefined, undefined, page, 10)
      .then(response => setPosts(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [category, page, pageSize]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.help.pages.group', href: `/h` }];
    if (category) {
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/h/c/${categoryTranslation?.url ?? category?.id}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [category]);

  return (
    <>
      <MetaHead title="Pomoc" description="Dokumenty pomocy" />

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto">
        <div className="border-b border-gray-200 pb-5 mt-12 mb-5 flex justify-between ">
          <h3 className="text-2xl font-semibold leading-6 text-gray-900">{t('cms.help.pages.group')}</h3>
          <h3 className="text-2xl font leading-6 text-gray-300">{category ? categoryTranslation?.title : "All categories"}</h3>
        </div>
        <div className="my-8">
          {loading && <Spinner className="h-16 mx-auto" />}
          {!loading && posts?.items?.map(p => <HelpPageRow page={p} />)}
          {!loading && !posts?.items?.length && <p className="text-center my-12 text-gray-400">{t('cms.newsfeed.empty')}</p>}
        </div>
        <Pagination
          page={page}
          pageSize={pageSize}
          pageSizes={pageSizes}
          total={posts?.totalCount || 0}
          onSetPage={setPage}
          onSetPageSize={setPageSize}
          nextButton={posts?.hasNextPage || false}
          previousButton={posts?.hasPreviousPage || false}
          distance={3}
        />
      </div >
    </>
  )
}

export default HelpIndex;